@import "variables";

.container {
  position: fixed;
  right: 60px;
  bottom: 40px;
  z-index: 2;
}

.link {
  display: inline-block;
  padding: 0 12px;
  margin-left: 48px;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.2em;

  &:hover {
    background: $white;
    color: $black;
  }
}
