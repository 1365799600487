// colors
:root {
  --white:        255, 255, 255;
  // --black:        10, 10, 10;
  --black:        0, 0, 0;

  --blue:         98, 122, 255;
  --pink:         249, 65, 197;
  --green:        32, 226, 143;
  --yellow:       213, 252, 57;
  --red:          230, 29, 91;

  // original theme
  --background:   var(--black);
  --foreground:   var(--white);
  --highlight:    var(--blue);
}

$background:      rgba(var(--background), 1);
$foreground:      rgba(var(--foreground), 1);
$foreground05:    rgba(var(--foreground), 0.05);
$foreground10:    rgba(var(--foreground), 0.1);
$foreground20:    rgba(var(--foreground), 0.2);
$foreground50:    rgba(var(--foreground), 0.5);
$foreground70:    rgba(var(--foreground), 0.7);
$foreground80:    rgba(var(--foreground), 0.8);
$highlight:       rgba(var(--highlight), 1);
$highlight05:     rgba(var(--highlight), 0.05);
$highlight10:     rgba(var(--highlight), 0.1);

$blue: rgba(var(--blue), 1);
$pink: rgba(var(--pink), 1);
$green: rgba(var(--green), 1);
$yellow: rgba(var(--yellow), 1);
$red: rgba(var(--red), 1);

$black: rgba(var(--black), 1);
$black50: rgba(var(--black), 0.5);
$black80: rgba(var(--black), 0.8);
$black90: rgba(var(--black), 0.9);
$white: rgba(var(--white), 1);
$white70: rgba(var(--white), 0.7);
$white80: rgba(var(--white), 0.8);

$headlineFont: titling-gothic-fb, -apple-system, BlinkMacSystemFont, sans-serif;
$bodyFont: source-code-pro, Monaco, monospace;

@mixin section {
  min-height: 100vh;
}

@mixin content {
  position: relative;
  z-index: 1;
}
