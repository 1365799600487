@import "variables";
@import "typography";
@import "grid";

.container {
  @include section;
  padding: 16vh 0 28vh;
  background: $white;
  color: $black;
}

.intro {
  @include content;
  width: getSpan(6);
  margin-left: getOffset(1);
  @include projectIntro;
}

.name {
  color: #627AFF;
}
