@import "reset";
@import "variables";

* {
  box-sizing: border-box;
}

body,
input,
button,
textarea,
select {
  font-family: $bodyFont;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  -webkit-font-smoothing: antialiased;
  color: $foreground;
}

body {
  background: $background;
}
