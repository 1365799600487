@import "variables";
@import "grid";

.container {
  position: relative;
  background: $black;
  color: $white;
}

.masthead {
  position: relative;
  height: 100vh;
}

.details {
  @include content;
  position: absolute;
  // left: getOffset(1);
  left: 60px;
  top: 15vh;
}

.name {
  font-family: $headlineFont;
  font-size: 48px;
}

.title {
  margin-top: 8px;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.2em;
}

.intro {
  @include content;
  padding: 20vh getOffset(2);
  min-height: 100vh;
}

.mainBlurb {
  font-family: $headlineFont;
  font-size: 36px;
  line-height: 1.6;
  color: $white70;

  strong {
    color: $white;
  }
}
