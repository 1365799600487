@import "variables";
@import "typography";
@import "grid";

.container {
  @include section;
  // @include content;
  padding: 16vh 0 28vh;
  // background: $black80;
  color: $white;
}

.details {
  @include content;
  margin-left: getOffset(1);
  width: getSpan(8);
}

.intro {
  @include projectIntro;
}

.name {
  color: #ff3366;
}

.role {
  margin-top: 80px;
  font-size: 14px;
  line-height: 1.6;
  text-transform: uppercase;
  letter-spacing: 0.2em;

  li {
    &:before {
      content: '_';
      color: $white70;
    }
  }
}
